._1RLww {
  text-align: center;
}

._RU-K2 {
  margin: 1rem;
  width: 303px;
  height: 38px;
  background-color: #00c300;
  color: white;
  font-size:1rem;
  font-weight:500;
  line-height: 2.4rem;
  /* background: url('line_login_button.png') no-repeat; */
  display: inline-block;
  border-radius: 4px;
}
._RU-K2:hover {
  /* background: url('line_login_button_hover.png') no-repeat; */
}
._RU-K2:active {
  /* background: url('line_login_button_press.png') no-repeat; */
}
