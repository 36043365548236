label {
  display: block;
  /* margin-top: 10px; */
}

.card-container.card {
  width: 100%;
  max-width: 320px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 10px;
  /* -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3); */
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.password-change-logo {
  height: 96px;
  margin: 20px auto 10px;
  display: block;
}

.navbar>img{
  max-width: 40px;
  max-height: 30px;
  margin-right: 10px;
}

.MuiDialog-root{
  top:0
  /* top : 0,
  left : 0,
  display: flex,
  align-items: center,
  justify-content: center */
}
.btn:focus {
  outline: none !important;
  outline-offset: none !important;
  border: none !important;
}
button:focus {
  outline: none !important;
}
.MuiDataGrid-cell,
.MuiDataGrid-cell:focus,
.MuiDataGrid-columnHeader,
.MuiDataGrid-columnHeader:focus{
  outline: none !important;
  outline-offset: none !important;
  caret-color: transparent;
}

.Mui-disabled,
input:disabled,
input[type='radio']:disabled,
input[type='radio']:disabled:after,
input[type='radio']:disabled:checked:after{
  caret-color: transparent;
}